import React from 'react'
import SideBar from '../../components/SideBar/SideBar'
import Header from '../../components/Header/Header'

const ManageStaff = () => {
  return (
    
    <>
      <div className="dashboard">
        <div className="sidebar">
          <SideBar />
        </div>
        <div className="dashboard-container">

          <div className="header">
            <Header />
          </div>
          
          <div className="heading-content">
              Manage Staff
          </div>
         
        </div>
      </div>
    </>
  )
}

export default ManageStaff