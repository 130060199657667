import React, { useState } from "react";
import "./Login.css";
import LeftSideContent from "../../components/LeftSideContent/LeftSideContent";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate()
  const [loginData, setLoginData] = useState({
    mail_or_mobile: "",
    password: "",
  });

  const handleInputChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const fetchLogin = (e) => {
    e.preventDefault(); 

    axios
      .post("https://user.tapntip.me/api/v1/auth/login", loginData)
      .then((response) => {
        console.log("Login successful:", response.data);
        navigate('/dashboard')

      })
      .catch((error) => {
        console.error("Login error:", error);
      });
  };

  return (
    <>
      <div className="loginContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <LeftSideContent />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="loginForm">
                <div className="signIn">Sign In</div>
                <form onSubmit={fetchLogin}>
                  <div style={{ paddingTop: "10px" }}>
                    <div className="mb-3">
                      <label htmlFor="email" className="loginLabel">
                        Email
                      </label>
                      <input
                        type="email"
                        name="mail_or_mobile"
                        className="form-control"
                        placeholder="Enter your email"
                        value={loginData.mail_or_mobile}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="loginLabel">
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Enter your password"
                        value={loginData.password}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3 ">
                      <p className="text-end">
                        <Link
                          to="/forget-password"
                          className="forgotPasswordLink"
                        >
                          Forgot Password?
                        </Link>
                      </p>
                    </div>
                    <button type="submit" className="buttonSubmit">
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
