import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date.css";
import dateIcon from "../../assets/images/calendar.png";

const DatePickerComp = () => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  return (
    <>
      <div className="date-picker-wrapper">
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          className="date-input"
          placeholderText=" From Date"
        />
        <span className={`date-icon ${fromDate ? "hidden" : ""}`}>
          <img src={dateIcon} alt="DateIcon" />
        </span>
      </div>
      <div className="date-picker-wrapper endDate">
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          className="date-input"
          placeholderText=" To Date"
        />
        <span className={`date-icon ${toDate ? "hidden" : ""}`}>
          <img src={dateIcon} alt="DateIcon" />
        </span>
      </div>
      <div className="reportButtonSection">
        <button className="reportDownloadBtn">Download</button>
      </div>
    </>
  );
};

export default DatePickerComp;
