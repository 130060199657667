import React from "react";
import LeftSideContent from "../../components/LeftSideContent/LeftSideContent";
import './forgetPassword.css';

const ForgetPassword = () => {
  return (
    <>
      <div className="forgetPasswordContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <LeftSideContent />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="forgetPasswordFormContainer">
                <div className="forgetPassword">Forgot Password ?</div>
                <div className="forgetPasswordEmail">Enter your email to reset your password.</div>
                <form>
                  <div className="forgetPasswordForm">
                    <div className="m-3">
                      <label htmlFor="email" className="loginLabel">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        aria-describedby="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="groupButton btnDecoration">
                        <div className="">
                        <button type="submit" className="cancelledForgetPassword">
                            Cancel
                        </button>
                        </div>
                        <div className="">
                        <button type="submit" className="buttonSubmitForgetPassword">
                        Submit
                        </button>
                        </div>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
