import React, { useState } from "react";
import "./dashboard.css";
import SideBar from "../../components/SideBar/SideBar";
import Header from "../../components/Header/Header";
import DatePickerComp from "../../components/date/date";

const Dashboard = () => {

  return (
    <>
      <div className="dashboard">
        <div className="sidebar">
          <SideBar />
        </div>
        <div className="dashboard-container">
          <div className="header">
            <Header />
          </div>

          <div className="heading-content">Welcome, Mark</div>

          <div className="activityMain">
            <div className="recentActivity">
              <div className="recentActivityHeading">Recent Activity</div>
              <div className="activityList">
                John Smith received a tip of $5
              </div>
              <span className="dottedLine"></span>
              <div className="activityList">Jane Doe received a tip of $10</div>
              <span className="dottedLine"></span>

              <div className="activityList">
                Alex Johnson received a tip of $3
              </div>
            </div>
            <div className="summaryTip">
              <div className="recentActivityHeading">Today’s Summary</div>
              <div className="activityList">
                Total Tips <span className="amountTip">$240</span>
              </div>
              <span className="dottedLine"></span>
              <div className="activityList">
                Number of Transactions <span className="amountTip">120</span>
              </div>
              <span className="dottedLine"></span>
              <div className="activityList">
                Average Tip Amount <span className="amountTip">$2</span>
              </div>
            </div>
            <div className="downloadReport">
              <div className="recentActivityHeading">Download Reports</div>
              <div className="activityList">
                <DatePickerComp />
              </div>
            </div>
          </div>

          <div className="secondSection">
              <div className="topPerformers">
                  <div className="recentActivityHeading">Top Performers - <span>July</span></div>
                  <div>Jane Doe <span className="amountTip">$300</span></div>
                  <span className="dottedLine"></span>
                  <div>John Smith <span className="amountTip">$250</span></div>
                  <span className="dottedLine"></span>
                  <div>Alex Johnson <span className="amountTip">$200</span></div>
                  <span className="dottedLine"></span>
                  <div>David Warner <span className="amountTip">$200</span></div>
              </div>
          </div>

          <div className="thirdSection">
              <div className="systemStatus">
                  <div>System Status</div>
                  <div>Connected devices: 10 </div>
                  <div>Alerts: 2 </div>
              </div>
              <div className="upcomingPayout">

              </div>
              <div className="quickTip">

              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
