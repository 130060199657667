import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./sidebar.css";
import TipNTapLogo from "../../assets/images/TapNtipLogo.png";
import arrowIcon from "../../assets/images/arrow-icon.png";

const SideBar = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const handleItemClick = (item) => {
    if (
      item !== "managepayout" &&
      item !== "payoutManagement" &&
      item !== "payoutHistory"
    ) {
      setIsSubMenuOpen(true);
    }

    switch (item) {
      case "Home":
        navigate("/dashboard");
        break;
      case "managestaff":
        navigate("/manage-staff");
        break;
      case "managedevice":
        navigate("/manage-device");
        break;
      case "payoutManagement":
        navigate("/payout-management");
        break;
      case "payoutHistory":
        navigate("/payout-history");
        break;
      case "settings":
        navigate("/settings");
        break;
      case "managepayout":
        setIsSubMenuOpen(!isSubMenuOpen);
        break;
      default:
        break;
    }

    setActiveMenu(item);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <div className="logoImage">
          <img src={TipNTapLogo} alt="logo" width="200" height="56" />
        </div>
      </div>
      <ul className="sidebar-menu">
        <li
          className={isActive("/dashboard") ? "active" : ""}
          onClick={() => handleItemClick("Home")}
        >
          Home
        </li>
        <li
          className={isActive("/manage-staff") ? "active" : ""}
          onClick={() => handleItemClick("managestaff")}
        >
          Manage Staff
        </li>
        <li
          className={isActive("/manage-device") ? "active" : ""}
          onClick={() => handleItemClick("managedevice")}
        >
          Manage Device
        </li>
        <li
          className={
            isActive("/manage-payout") || isSubMenuOpen ? "active" : ""
          }
          onClick={() => handleItemClick("managepayout")}
        >
          <span>Manage Payout</span>
          <span className={`arrow ${isSubMenuOpen ? "up" : "down"}`}>
            <img src={arrowIcon} alt="arrow icon" />
          </span>
        </li>
        {isSubMenuOpen && (
          <ul className={`sidebar-submenu ${isSubMenuOpen ? "open" : ""}`}>
            <li
              className={isActive("/payout-management") ? "active" : ""}
              onClick={() => handleItemClick("payoutManagement")}
            >
              Payout Management
            </li>
            <li
              className={isActive("/payout-history") ? "active" : ""}
              onClick={() => handleItemClick("payoutHistory")}
            >
              Payout History
            </li>
          </ul>
        )}
        <li
          className={isActive("/settings") ? "active" : ""}
          onClick={() => handleItemClick("settings")}
        >
          Settings
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
