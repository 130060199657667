import React from "react";
import profileImage from "../../assets/images/profile-image.png";
const Header = () => {
  return (
    <div className="profile-menu">
      <img src={profileImage} alt="profileImage" />
    </div>
  );
};

export default Header;
