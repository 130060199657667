import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import ManageStaff from "./pages/ManageStaff/ManageStaff";
import ManageDevice from "./pages/ManageDevice/ManageDevice";
import PayoutManagement from "./pages/ManagePayout/PayoutManagement/PayoutManagement";
import PayoutHistory from "./pages/ManagePayout/PayoutHistory/PayoutHistory";
import Settings from "./pages/Settings/Settings";

function App() {
  return (
    <>
      <Router>
      <Routes>
        <Route path="/" element={<Login />} /> 
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/manage-staff" element={<ManageStaff />} />
        <Route path="/manage-device" element={<ManageDevice />} />
        <Route path="/payout-management" element={<PayoutManagement />} />
        <Route path="/payout-history" element={<PayoutHistory />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </Router>
    </>
  );
}


export default App;
